<template>
	<div class="newsDetail">
		<div class="widthBox">
			<div class="newsTitle">
				<h2>{{newsData.title}}</h2>
				<p>Posted on: {{newsData.create_time}}</p>
			</div>
			<div class="newsCont" v-html="newsData.content">
				
			</div>
		</div>
	</div>
</template>

<script>
	export default {
	  name: 'news_detail',
	  components: {
	  },
	  data: function() {
	  	return {
	  		newsData:{},
	  	};
	  },
	  created() {
	  	this.$api.get('home/index/newsDetail',{
			id:this.$route.query.id
	  	}).then(res=>{
	  		this.newsData = res.data
	  	})
	  },
	  mounted() {
	  	this.$nextTick(function(){
	  		if(this.$route.query.to != undefined || this.$route.query.to != null){
	  			setTimeout(()=>{
	  				document.getElementById(this.$route.query.to).scrollIntoView()
	  			},500)
	  		}else{
	  			document.body.scrollIntoView()
	  		}
	  	})
	  },
	}
</script>

<style lang="scss" scoped="scoped">
	.newsDetail{
		width:100%;
		margin:0 auto;
		.newsTitle{
			padding:80px 0 40px;
			border-bottom:1px solid #1D2A55;
			h2{
				font-size:48px;
				text-align: center;
			}
			p{
				text-align: center;
				font-size:24px;
				margin-top:40px;
			}
		}
		.newsCont{
			padding:40px 0;
		}
		
	}
	@media screen and(max-width:768px) {
		.newsDetail{
			.newsTitle{
				padding:20px 0;
				border-bottom:none;
				border-bottom: 1px solid #E6E6E6;
				h2{
					font-size:16px;
				text-align: center;
				color: #272E4D;
				}
				p{
					text-align: center;
					font-size:10px;
					margin-top:10px;
					color: #8A8D99;
				}
			}
			.newsCont{
				padding:20px 0;
				::v-deep p{
					line-height: 24px !important;
					font-size: 14px !important;
					color: #272E4D !important;
				}
				::v-deep span{
					font-size: 14px !important;
					color: #272E4D !important;
				}
				::v-deep img{
					width: 100% !important;
					height: auto !important;
				}
			}
			
		}
	}
</style>
